<template>
    <Head>
        <title>Webviews Swingu</title>
        <meta
            type="description"
            content="Default meta description"
            head-key="description"
        />
        <meta charset="utf-8" />

        <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
        />
        <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
        />
        <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />

        <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, viewport-fit=cover"
        />
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
    </Head>
    <main
        data-theme="default"
        :data-webview="true"
        class="antialiased font-swingu"
    >
        <!--
        <div class="relative flex z-[40]">
            <div
                v-if="$page.props.devFlags.show_webview_debug"
                class="ml-auto mt-[2px] mr-[2px] p-sm text-2xs cursor-pointer"
                @click="showDebug = !showDebug"
            >
                <SettingsIcon fill="#ef4444cc" />
            </div>
            <div
                v-if="showDebug"
                class="absolute w-full top-full left-0 right-0 bg-black/80 text-white text-2xs z-10 scroll-auto p-xl"
            >
                <div class="font-bold">
                    Headers:
                </div>
                <div
                    v-for="(header, key) in headers"
                    :key="key"
                    class="flex max-w-full overflow-hidden"
                >
                    <div class="font-bold">{{ key }}:</div>
                    <div class="ml-md">
                        {{ header }}
                    </div>
                </div>
            </div>
        </div>
        -->

        <slot />
    </main>
</template>

<script setup>
import { useSetDeviceType } from "@components/global/composables/webview-events";
import { onMounted, ref } from "vue";
import SettingsIcon from "@components/coach-swingu/svgs/settings-icon.vue";

// props defined here in Vue composition api, cannot also define in Vue options api below
const props = defineProps({
    subdomainData: {},
    layoutData: {},
    headers: {
        type: Array,
        default: [],
    },
});

const showDebug = ref(false);

if (typeof props.subdomainData.deviceType === 'undefined' && typeof props.layoutData.deviceType === 'undefined') {
    throw new Error('Neither `props.subdomainData.deviceType` nor `props.layoutData.deviceType` are defined');
}

useSetDeviceType(props.layoutData?.deviceType ?? props.subdomainData?.deviceType ?? "ios");

onMounted(() => {
    // Clicking into webviews opens them scrolled down about half way. This mitigates the issue and ensures they open scrolled to the top
    setTimeout(() => {
        window.scrollTo({
            top: -1,
            left: 0,
            behavior: "smooth",
        }),
            100;
    });
});
</script>
